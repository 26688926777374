import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { FormattedMessage } from 'react-intl';
import { FormattedHTMLMessage } from '@bibliocommons/react-intl';
import { Checkmark, Spinner } from '@bibliocommons/base-icons';
import { TransactionalSolidButton, SecondarySolidButton } from '@bibliocommons/base-buttons';
import { ModalHeader, ModalContent, ModalFooter } from '@bibliocommons/modal';
import { EVENTS_PDF_URL, BROCHURE_LIMIT } from 'app/constants/EventsConstants';
import ModalActions from 'app/actions/ModalActions';

import './EventsBrochureModal.scss';

export default function EventsBrochureModal({ pagination, modalKey }) {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isFetching, setIsFetching] = useState(pagination.get('count') < BROCHURE_LIMIT);
  const [isComplete, setIsComplete] = useState(false);
  const downloadUrl = `${EVENTS_PDF_URL}${location?.search ? location?.search : ''}`;

  useEffect(() => {
    let isMounted = true;
    new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, 5000);
    }).then(() => {
      if (isMounted && isFetching) {
        setIsComplete(true);
      }
    });
    return () => {
      isMounted = false;
    };
  }, [isFetching]);

  function handleClose() {
    dispatch(ModalActions.closeModal(modalKey));
  }

  function createAndDownloadPdf() {
    setIsFetching(true);
    window.open(downloadUrl);
  }

  function renderLoadingState() {
    return (
      <div className="creating-pdf">
        <Spinner primary />
        <span>
          <FormattedMessage defaultMessage="Creating PDF..." id="pdf.events.creating" />
        </span>
      </div>
    );
  }

  function renderDownloadComplete() {
    if (isComplete) {
      return (
        <>
          <ModalContent>
            <div className="pdf-ready">
              <div className="pdf-ready-text">
                <Checkmark />
                <FormattedMessage defaultMessage="PDF ready for download" id="pdf.events.ready" />
              </div>
              <div className="pdf-download-link">
                <FormattedHTMLMessage
                  defaultMessage="<p>If the PDF download doesn't begin in a few seconds <a href='{downloadUrl}' target='_blank'>click here to manually start the download.</a></p>"
                  id="pdf.events.download.text"
                  values={{ downloadUrl }}
                />
              </div>
            </div>
          </ModalContent>
          <ModalFooter
            className="download-complete"
            primaryActionButtonText={<FormattedMessage defaultMessage="Done" id="done" />}
            primaryActionButtonTag={SecondarySolidButton}
            handlePrimaryAction={handleClose}
            dataKey="pdf-download-done"
          />
        </>
      );
    }
    return <ModalContent>{renderLoadingState()}</ModalContent>;
  }

  function renderDownloadModal() {
    if (!isFetching && pagination?.get('count') >= BROCHURE_LIMIT) {
      return (
        <>
          <ModalContent>
            <FormattedHTMLMessage
              defaultMessage="A PDF can only be created with 200 or fewer events."
              id="pdf.events.description"
            />
          </ModalContent>
          <ModalFooter
            primaryActionButtonText={
              <FormattedMessage defaultMessage="Create with first 200 events" id="pdf.events.create_button" />
            }
            primaryActionButtonTag={TransactionalSolidButton}
            secondaryActionButtonText={<FormattedMessage defaultMessage="Cancel" id="button_cancel" />}
            handlePrimaryAction={createAndDownloadPdf}
            dataKey="events-pdf-modal"
          />
        </>
      );
    }
    return renderDownloadComplete();
  }

  return (
    <div className="cp-events-brochure-modal">
      <ModalHeader title={<FormattedMessage defaultMessage="Create PDF" id="create_pdf" />} />
      {isFetching ? renderDownloadComplete() : renderDownloadModal()}
    </div>
  );
}

EventsBrochureModal.propTypes = {
  pagination: ImmutablePropTypes.map,
  modalKey: PropTypes.string.isRequired
};
